@use "../../../scss/abstracts/" as *;

.card-Table {
  position: relative;
  margin: 0 0 50px;

  .bottom-pagination {
    position: absolute;
    left: 0;
    width: 100%;
    bottom: -90px;
    // @media (min-width: 1199px) and (max-width: 1738px) {
    //   bottom: -115px;
    // }
  }
  @media (max-width: 557px) {
    margin: 0 0 40px;
  }
}

.justif-viewer {
  &.file-box-item {
    font-size: 1rem;

    span {
      text-decoration: underline;
      max-width: 150px;
    }

    svg {
      margin: 0 5px;
      color: $secondaryColor;
      font-size: 1.2rem;
    }
  }
}

.category {
  &.file-box-item {
    font-size: 1.05rem;
  }
}

.custom-card {
  .rdt_TableHeadRow {
    .rdt_TableCol {
      &:last-child {
        min-width: 130px;
        justify-content: flex-start;
      }
    }
  }

  .rdt_TableRow {
    .rdt_TableCell {
      &:last-child {
        justify-content: flex-start;
      }
    }
  }
}
