@use "../../../scss/abstracts/" as *;

.main-register {
  position: relative;
  padding: 0 20px;
  @media (max-width: 576px) {
    padding: 0 5px;
  }
}

.carouselContainer {
  direction: ltr;
  width: 100%;
}

.main {
  position: relative;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;

    background-repeat: no-repeat;
    background-size: 100%;
    width: 359px;
    height: 900px;
    z-index: -1;
    left: 0;
    top: 0;
  }
}
.mainInner {
  position: relative;
}
.steperWrap {
  display: flex;
  justify-content: center;
}
.navStep {
  background: #f0f3f4;
  display: flex;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  box-shadow: inset 0px 4px 6px 0px lighten($secondaryColor, 30);

  position: relative;
  z-index: 9;

  .innerspan {
    background: rgb(80, 177, 220);
    box-shadow: 0px 20px 20px lighten($secondaryColor, 20);
    color: $white;
    font-size: 1.7rem;
    transform: none;
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
  }
  &.warning {
    .innerspan {
      background: $orange !important;
      box-shadow: 0px 20px 20px lighten($orange, 20);
    }
  }
}
.btnsAction {
  padding: 25px 0;
  text-align: center;
}

.colItem {
  @media only screen and (max-width: 991px) {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
}
.titleSection {
  padding: 0 0 50px;
  text-align: center;
  h2 {
    color: #fff;
    font-size: 2.47rem;
    line-height: 2.96rem;
    font-weight: 700;
  }
}
.btnGoTo {
  min-width: 265px;
}
.stepperSection {
  padding: 20px 0;
  .container-md{
    @media (max-width: 576px) {
      padding: 0;
    }
  }
}

.vedioSection {
  padding: 50px 0;
}
.bottomSteper {
  text-align: right;
  padding: 50px 0;
}
.bottomSteperCenter {
  text-align: center;
  padding: 25px 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  .btn {
    min-width: 170px;
    min-height: 50px;
    margin: 7px;
    font-size: 1.1rem;
    > span {
      display: inline-block;
      padding: 0 10px;
    }
  }
  @media (max-width: 450px) {
    padding: 25px 0;
    .btn {
      margin: 7px 0;
      width: 100%;
    }
  }
}

.stepper {
  position: relative;
  width: 80px;
  @media (max-width: 640px) {
    width: 55px;
  }
  @media (max-width: 450px) {
    width: 47px;
  }
}
// .iconStep {
//   cursor: pointer;
// }
.scrollBar {
  position: absolute;
  height: 6px;
  background-color: #f0f3f4;
  box-shadow: inset 0px 3px 6px lighten(#51b4d2, 35);
  border-radius: 8px;
  overflow: hidden;
  top: 17px;
  left: 25px;
  right: -10px;
  z-index: 0;
}
.porgress {
  position: absolute;
  left: 0;
  top: 0;
  height: 6px;
  background-color: $secondaryColor;
  border-radius: 8px;
  z-index: 9;
}

.cercleSpan {
  position: absolute;
  right: 0;
  top: 0;
}
