@use "./variables" as *;

.form-file {
  border-color: $primaryColor;
  color: $primaryColor;
  box-shadow: inset 0px 4px 5px 0px lighten($black, 85);
  border-radius: 23px;
  min-height: 40px;
  padding: 25px 35px;
  border: 2px dashed #ccc;
  text-align: center;
  @media (max-width: 991px) {
    padding: 15px;
  }

  input[type="file"] {
    opacity: 0;
    display: none;
  }

  &.file-primary {
    box-shadow: inset 0px 4px 6px 0px lighten($primaryColor, 25);
    border-color: $primaryColor;

    .file-box {
      box-shadow: inset 0px 4px 6px 0px lighten($primaryColor, 25);
      color: $primaryColor;
    }
  }

  &.file-secondary {
    box-shadow: inset 0px 4px 6px 0px lighten($secondaryColor, 25);
    border-color: $secondaryColor;

    .file-box {
      box-shadow: inset 0px 4px 6px 0px lighten($secondaryColor, 25);
      color: $secondaryColor;
    }
  }

  &.file-warning {
    box-shadow: inset 0px 4px 6px 0px lighten($orange, 25);
    border-color: $orange;

    .file-box {
      box-shadow: inset 0px 4px 6px 0px lighten($orange, 25);
      color: $orange;
    }
  }

  &.file-danger {
    box-shadow: inset 0px 4px 6px 0px lighten($red, 25);
    border-color: $red;

    .file-box {
      box-shadow: inset 0px 4px 6px 0px lighten($red, 25);
      color: $red;
    }
  }

  .file-box {
    color: #b0b0b0;
    background: $white;
    height: 45px;
    border-radius: 15px;
    font-size: 1.8rem;
    font-family: $tt-medium;
    padding: 5px 25px;
    box-shadow: inset 0px 4px 5px 0px lighten($black, 85);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    span {
      font-size: 1.15rem;
      line-height: 18px;
      @media (max-width: 991px) {
        font-size: 1rem;
      }
    }

    img {
      max-width: 40px;
      top: 2px;
      position: relative;
      margin: 0 10px 0 0;
    }

    svg {
      max-width: 40px;
      margin: 0 10px 0 0;
    }

    @media (max-width: 576px) {
      height: 40px;
      img {
        max-width: 22px;
        margin: 0 5px 0 0;
      }

      svg {
        max-width: 22px;
        margin: 0 5px 0 0;
      }
    }

    @media (min-width: 767px) and (max-width: 1070px) {
      padding-left: 5px;
      padding-right: 5px;
      svg {
        margin: 0 5px 0 0;
      }
    }
  }
}

.label-file {
  display: block;
  font-size: 1.3rem;
  color: #b0b0b0;
  margin: 0 0 15px;
  @media (max-width: 991px) {
    font-size: 1.3rem;
  }
}

.file-box-item {
  cursor: pointer;
  color: #b0b0b0;
  background: rgba(152, 152, 152, 0.08);
  height: 45px;
  border-radius: 35px;
  font-size: 1.1rem;
  font-family: $tt-regular;
  padding: 5px 25px 5px 15px;
  box-shadow: inset 0px 4px 5px 0px lighten($black, 85);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 5px;

  span {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    display: inline-block;
    max-width: 210px;
    white-space: nowrap;
  }

  pre {
    margin-bottom: 0 !important;
  }
  &.transaction-file {
    justify-content: flex-start;
    max-width: 100%;
    span {
      max-width: 90%;
    }
  }
}

.file-box-item-with-clickers {
  cursor: pointer;
  color: #b0b0b0;
  background: rgba(152, 152, 152, 0.08);
  height: 45px;
  border-radius: 35px;
  font-size: 1.1rem;
  font-family: $tt-regular;
  padding: 5px 25px 5px 15px;
  box-shadow: inset 0px 4px 5px 0px lighten($black, 85);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 5px;
  min-width: 180px;

  @media (max-width: 360px) {
    max-width: 220px;
  }

  .file-viewer-click {
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    white-space: nowrap;
    width: 100%;
    max-width: 227px;
  }

  .file-delete-click {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    max-width: 40px;
    display: block;
    text-align: center;
    white-space: nowrap;
    position: relative;

    &:after {
      content: "";
      position: absolute;
      border-left: 12px solid rgba(152, 152, 152, 0.2);
      left: -10px;
      height: 100%;
    }
  }

  pre {
    margin-bottom: 0 !important;
  }
}

.file-primary + .list-Files {
  margin-top: 15px;

  .file-box-item,
  .file-box-item-with-clickers {
    cursor: pointer;
    color: $primaryColor;

    svg path {
      fill: $primaryColor;
    }
  }
}

.file-secondary + .list-Files {
  margin-top: 15px;

  .file-box-item,
  .file-box-item-with-clickers {
    cursor: pointer;
    color: $secondaryColor;

    svg path {
      fill: $secondaryColor;
    }
  }
}

.file-warning + .list-Files {
  margin-top: 15px;

  .file-box-item,
  .file-box-item-with-clickers {
    cursor: pointer;
    color: $orange;

    svg path {
      fill: $orange;
    }
  }
}
