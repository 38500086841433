@use "../../scss/abstracts/" as *;

.card-image {
  border: 2px solid #ffffffe3;
  border-radius: 40px;
  min-height: 250px;
  height: calc(100% - 30px);
  box-shadow: -5px 5px 30px lighten($secondaryColor, 25);
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  @media (max-width: 576px) {
    box-shadow: -2px 5px 20px lighten($secondaryColor, 28);
   
  }
  .card-img {
    width: 200px;
    height: 200px;
    background: url(../../assets/images/svg/form-bg.svg) no-repeat center;
    background-size: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      max-height: 200px;
      width: auto;
      max-width: 200px;
      position: relative;
      top: 10px;
    }
    @media (max-width: 1700px) and (min-width: 1301px) {
      width: 150px;
      height: 150px;
      img {
        max-height: 125px; 
        max-width: 125px; 
      }
    }
    @media (max-width: 1300px) {
      width: 130px;
      height: 130px;
      img {
        max-height: 110px; 
        max-width: 110px; 
      }
    }
    @media (max-width: 1199px) {
      width: 100px;
      height: 100px;
      img {
        max-height: 80px; 
        max-width: 80px; 
      }
    }
    
  }

  .card-body {
    display: flex;
    align-items: center;
    text-align: center;
    width: 100%;
    justify-content: center;
    flex-direction: column;
  }
  h3,
  p {
    font-size: 1.2rem;
    color: $black;
    font-family: $tt-demibold;
    margin-top: auto;
    margin-bottom: 15px !important;
  }
  .btnBottom {
    min-width: 100px;
    border-radius: 25px;
    height: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: $white;
    font-size: 2rem;
    background: $secondaryColor;
    margin-top: auto;
    position: relative;
    box-shadow: inset 0px 4px 5px 0px darken($secondaryColor, 12);
    cursor: pointer;
    input {
      opacity: 0;
      position: absolute;
    }
    @media(max-width: 1199px) and (min-width:992px){
        min-width: 80px;   
    }
  }
  

  &:hover {
    transform: translateY(-10px);
    transition: all 0.2s ease-in-out;
  }
}
