@use "../../scss/abstracts/" as *;

.form__container {
  margin-top: 2.5rem;
  .main-User-client {
    .client-wrrap > .tabs-component {
      > .tablist {
        display: flex;
        background: transparent;
        box-shadow: none;

        .btn {
          flex: 1 0;
          padding: 0.5rem;
          font-size: 1.1rem;
          background: $white;
          box-shadow: -2px 4px 15px lighten($secondaryColor, 30);
          margin: 0 10px;

          &.active {
            background-color: $white;
            color: $secondaryColor;
            box-shadow: inset 0px 4px 5px 0px lighten($secondaryColor, 15);
          }
        }
      }
    }

    @media (max-width: 991px) {
      .topnav {
        padding: 15px;
        margin: 0;
        box-shadow: none;
      }
    }
  }

  .form__footer {
    justify-content: center;
    border-top: none;
    padding: 15px 15px 35px;

    .form__button {
      max-width: 150px;
      margin: 5px 10px;
    }
  }

  .profile__photo {
    display: flex;
    justify-content: top;
    align-items: center;
    flex-direction: column;

    @media (max-width: 991px) {
      margin-bottom: 10px;
    }
  }

  .user-info {
    @include flex(center, center);
    background-color: $mainBg;
    border-radius: $borderRadius;
    height: 190px;
    width: 190px;
    // background: url(../../assets/images/cercle-bg.png) no-repeat;
    margin-bottom: 10px;

    &__img {
      --size: 160px;
      width: var(--size);
      height: var(--size);
      border-radius: 50%;
      overflow: hidden;

      img {
        width: 160px;
        height: 160px;
        object-fit: cover;
        @media (max-width: 991px) {
          max-width: 125px !important;
          max-height: 125px !important;
        }
      }
      @media (max-width: 1450px) {
        width: 125px !important;
        height: 125px !important;

        display: flex;
        justify-content: center;
        align-items: center;

        img {
          max-width: 125px !important;
          max-height: 125px !important;
        }
      }
      @media (max-width: 991px) {
        width: 125px !important;
        height: 125px !important;
      }
    }

    &__name {
      padding: 0 $spacing;
      font-size: $fontSizeMedium;
      font-weight: 700;

      @include tablet {
        padding: 0 $mobileSpacing;
      }
    }
    @media (max-width: 1450px) {
      width: 160px !important;
      height: 160px !important;
    }
    @media (max-width: 991px) {
      .bgCercle {
        display: block;
      }
    }
    @media (min-width: 992px) and (max-width: 1120px) {
      width: 145px !important;
      height: 145px !important;
    }
  }
}

.form__button {
  box-shadow: -5px 5px 20px lighten($secondaryColor, 15) !important;
  cursor: pointer;
  .icon-btn {
    font-size: 25px;
    margin: 0 5px 0 0;
  }
}
