@use "../../../../scss/abstracts/" as *;

.register-stepFour {
  position: relative;
  padding : 30px 0 0;
  @media (max-width: 1400px) {
    width: 100%;
    flex: 1 0 auto;
  }
  @media (max-width: 450px) {
    padding : 30px 0 0;
  }
}
.checkform-box {
  margin-top: 20px;
}
.box-message {
  box-shadow: -15px 15px 30px rgba(79, 178, 217, 0.45);
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 15px 0;
  padding: 50px  10px;
  position: relative;
  > * {
    position: relative;
    z-index: 9;
  }
  &:after {
    content: "";
    position: absolute;
    border-radius: 40px;
    background-image: linear-gradient(
      to bottom,
      #f1f9fc,
      #f5fafe,
      #f9fcfe,
      #fdfdff,
      #ffffff
    );
    top: -2px;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 0;
  }
  p {
    font-family: $tt-demibold;
    font-size: 1.2rem;
    line-height: 25px;
    margin-bottom: 15px;
    color: #000;
    text-align: center;
    @media (max-width: 576px) {
      font-size: 1.12rem;
      line-height: 22px;
      padding: 0 10px;
    }
  }
  .actions-message {
    padding: 15px 0;
  }
  .btn {
    min-width: 120px;
    margin: 5px;
    @media (max-width: 576px) {
      min-width: 100px;
    }
  }
}
