@use "../../scss/abstracts/" as *;
.top-card-icon {
  width: 90px;
  height: 90px;
  background: #0d82b3;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 31px;
  position: absolute;
  top: -60px;
  left: 50%;
  transform: translateX(-50%);

  img {
    max-width: 60px;
    height: auto;
    position: relative;
    top: 10px;
  }
}
.card-register {
  border: 2px solid #ffffffe3;
  border-radius: 40px;
  min-height: 250px;
  height: calc(100% - 30px); 
  cursor: pointer;
  @media (max-width: 1199px) {
    height: calc(100% - 70px);
    margin-bottom: 70px; 
  }
  &.card-blue {
    box-shadow: -15px 15px 30px lighten($secondaryColor, 25);
    .top-card-icon {
      box-shadow: -15px 15px 30px lighten($secondaryColor, 25);
      background-image: linear-gradient(
        to right,
        #0d82b3,
        #238fbf,
        #339ccb,
        #41a9d7,
        #4fb7e3
      );
    }
    .btnBottom {
      background: $secondaryColor;
      box-shadow: inset 0px 4px 5px 0px darken($secondaryColor, 12);
    }
  }
  &.card-green {
    box-shadow: -15px 15px 30px lighten($green, 25);
    .top-card-icon {
      box-shadow: -15px 15px 30px lighten($green, 25);
      background-image: linear-gradient(
        to right,
        #2d947d,
        #3ba68d,
        #49b89e,
        #56cbaf,
        #64dec1
      );
    }
    .btnBottom {
      background: $green;
      box-shadow: inset 0px 4px 5px 0px darken($green, 12);
    }
  }
  &.card-orange {
    box-shadow: -15px 15px 30px lighten($orange, 25);
    .top-card-icon {
      box-shadow: -15px 15px 30px lighten($orange, 25);
      background-image: linear-gradient(
        to right,
        #ef8d16,
        #f39629,
        #f7a038,
        #fba946,
        #ffb254
      );
    }
    .btnBottom {
      background: $orange;
      box-shadow: inset 0px 4px 5px 0px darken($orange, 12);
    }
  }
  &.card-purple {
    box-shadow: -15px 15px 30px lighten($purple, 25);
    .top-card-icon {
      box-shadow: -15px 15px 30px lighten($purple, 25);
      background-image: linear-gradient(
        to right,
        #603dd9,
        #7555e2,
        #886cea,
        #9b82f2,
        #ae99f9
      );
    }
    .btnBottom {
      background: $purple;
      box-shadow: inset 0px 4px 5px 0px darken($purple, 12);
    }
  }
  &.card-red {
    box-shadow: -15px 15px 30px lighten($red, 25);
    .top-card-icon {
      box-shadow: -15px 15px 30px lighten($red, 25);
      background-image: linear-gradient(
        to right,
        #e8422f,
        #ee5744,
        #f46a59,
        #f87c6e,
        #fb8e82
      );
    }
    .btnBottom {
      background: $red;
      box-shadow: inset 0px 4px 5px 0px darken($red, 12);
    }
  }
  .card-body {
    display: flex;
    text-align: center;
    width: 100%;
    justify-content: center;
    flex-direction: column;
    padding: 25px 0 0;
  }
  h3,
  p {
    font-size: 1.2rem;
    color: $black !important;
    font-family: $tt-demibold;
    margin-top: auto;
    margin-bottom: 20px;
    @media (max-width: 576px) {
      font-size: 1.05rem;
      line-height: 20px;
    }
  }
  .card-list {
    padding: 15px;
  }
  .box-list {
    display: flex;
    padding: 7px 0;
    justify-content: space-between;
    align-items: center;
    h3 {
      margin: 0 !important;
    }
    @media (max-width: 450px) {
      border-bottom: 1px dashed rgba(0,0,0,.12);
      &:last-child{
        border-bottom: none;
      }
    }
     
  }
  .card-footer {
    margin-top: auto;
    background: transparent;
    border: none;
    padding: 0;
  }
  .btn-infos {
    padding: 5px;
    margin-bottom: 5px;
    min-height: 40px;
  }
  .btnBottom {
    min-width: 100px;
    border-radius: 25px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $white;
    font-size: 2rem;
    background: $secondaryColor;
    margin-top: auto;
    position: relative;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    &:hover {
      background: $black;
      box-shadow: none;
      transition: all 0.3s ease-in-out;
    }

    input {
      opacity: 0;
      position: absolute;
    }
  }
  // &:hover{
  //   transform: translateY(-10px);
  // }
}
