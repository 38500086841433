@use "../../../../scss/abstracts/" as *;
.ask-card {
  padding: 20px 50px;
  @media (max-width: 1199px) {
    padding: 20px ;
  }
  .ask-form-title {
    font-weight: 700;
    h3{
      font-weight: 700;
      color: #000;
    }
  }
  .register-stepTow {
    position: relative;
    padding-top: 90px;
  }
  .row-wrap {
    flex-wrap: nowrap;
    @media (max-width: 1199px) {
      flex-wrap: wrap;
      justify-content: center;
    }
  }
  .box-col {
    margin-bottom: 10px;
    @media (max-width: 1199px) {
      flex: 0 0 auto;
      width: 33.33333333%;
    }
    @media (max-width: 576px) {
      flex: 0 0 auto;
      width:50%;
    }
  }
}

.warning-message {
  margin-top: 80px;
  padding: 80px 0 50px;
  position: relative;
  background-image: linear-gradient(
    to bottom,
    #fec04d,
    #fbb43f,
    #f8a731,
    #f59a22,
    #f28d12
  );
  box-shadow: -15px 15px 30px rgba(242, 143, 20, 0.3);
  border: 2px solid rgba(255, 255, 255, 0.65);
  .iconMsg {
    background: #fff;
    box-shadow: -15px 15px 30px rgba(98, 209, 186, 0.3);
    border: 2px solid rgba(255, 255, 255, 0.3);
    border-radius: 40px;
    width: 110px;
    height: 110px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -50px;
    left: 50%;
    transform: translateX(-50%);

    &:after {
      content: "";
      position: absolute;
      border-radius: 40px;
      background-image: linear-gradient(
        to bottom,
        #fdf5ea,
        #fff6f2,
        #fff9fa,
        #fffcfe,
        #ffffff
      );
      top: -2px;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 0;
    }
    img {
      max-width: 50px;
      height: auto;
      position: relative;
      z-index: 9;
    }
  }
  h4 {
    font-family: $tt-demibold;
    font-size: 1.4rem;
    line-height: 25px;
    margin-bottom: 15px;
    color: #fff;
    text-align: center;
  }
  p {
    font-family: $tt-medium;
    font-size: 1.2rem;
    line-height: 22px;
    margin-bottom: 15px;
    color: #fff;
    text-align: center;
  }
  address {
    font-family: $tt-medium;
    font-size: 1.2rem;
    line-height: 22px;
    color: #fff;
    text-align: center;
    a{
      text-decoration: underline;
    }
  }
}

.success-message {
  margin-top: 80px;
  padding: 80px 0 50px;
  position: relative;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(
    to right,
    #62d1ba,
    #53c6ae,
    #44bca2,
    #34b196,
    #1fa78a
  );
  box-shadow: -15px 15px 30px rgba(98, 209, 186, 0.3);
  border: 2px solid rgba(255, 255, 255, 0.65);
  .iconMsg {
    background: #fff;
    box-shadow: -15px 15px 30px rgba(98, 209, 186, 0.3);
    border: 2px solid rgba(255, 255, 255, 0.3);
    border-radius: 40px;
    width: 110px;
    height: 110px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -50px;
    left: 50%;
    transform: translateX(-50%);

    &:after {
      content: "";
      position: absolute;
      border-radius: 40px;
      background-image: linear-gradient(
        to bottom,
        #fdf5ea,
        #fff6f2,
        #fff9fa,
        #fffcfe,
        #ffffff
      );
      top: -2px;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 0;
    }
    img {
      max-width: 50px;
      height: auto;
      position: relative;
      z-index: 9;
    }
  }
  h4 {
    font-family: $tt-demibold;
    font-size: 1.4rem;
    line-height: 25px;
    margin-bottom: 15px;
    color: #fff;
    text-align: center;
  }
  p {
    font-family: $tt-medium;
    font-size: 1.2rem;
    line-height: 22px;
    margin-bottom: 15px;
    color: #fff;
    text-align: center;
  }
}
