@use "../../scss/abstracts/" as *;

.actionsFilter {
  display: flex;

  .btn {
    margin: 0 5px;
    flex: 1 0;
  }
}

.action-top-nav {
  position: absolute;
  top: -82px;
  right: 75px;

  .btn {
    margin: 0 7px;
  }
  @media (max-width: 991px) {
    position: static;
    text-align: right;
    .btn {
      margin: 0 5px 12px;
    }
  }
}

.btn.btn-creat-user {
  min-width: 240px;
  min-height: 54px !important;
  @media (max-width: 576px) {
    min-height: 45px !important;
    svg,
    img {
      max-width: 25px !important;
    }
  }
  @media (max-width: 450px) {
    width: 100%;
    margin: 0 0 12px;
  }

  svg,
  img {
    margin: 0 15px 0 0 !important;
    max-width: 28px !important;
  }
}

.btn.btn-creat-lead {
  min-width: 200px;
  min-height: 54px !important;
  @media (max-width: 576px) {
    min-height: 45px !important;
    svg,
    img {
      max-width: 25px !important;
    }
  }
  @media (max-width: 450px) {
    width: 100%;
    margin: 0 0 12px;
  }
  svg,
  img {
    margin: 0 15px 0 0 !important;
    max-width: 28px !important;
  }
}

.actionsFilter {
  display: flex;

  .btn {
    margin: 0 5px;
    flex: 1 0;
  }

  @media (max-width: 991px) {
    padding: 30px 0;
    justify-content: center;

    .btn {
      flex: none;
      min-width: 120px;
    }
  }
}

.text-area-custom {
  padding: 20px !important;
}

.prospects-filter {
  @media (min-width: 991px) and (max-width: 1037px) {
    label.form-label {
      max-width: 150px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
