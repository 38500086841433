@use "../../scss/abstracts/" as *;
.converter-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  .converter-content {
    .converter-title {
      text-align: center;
      font-weight: 800;
      font-family: "TT Commons", sans-serif !important;
      font-size: 48px;
      line-height: 50px;
      padding: 15px;
      margin-bottom: 15px;
    }
    .converter-description {
      text-align: center;
      a {
        color: $primaryColor;
      }
    }
    .converter-card {
      padding: 50px;
      box-shadow: -2px 4px 15px #d0eaf6;

      h3 {
        text-align: center;
        font-weight: bold;
      }
      .converter-inputs {
        margin: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-align: center;
        input {
          background: none;
          border: 1px solid #50b1dc;
          box-shadow: none;
          color: black;
          &::placeholder {
            text-align: center;
            color: gray;
            font-weight: bold;
            opacity: 0.3;
          }
        }
        .converter-action-btn-wrapper{
          margin-top: 20px;
        }
      }
    }
  }
}
