@use "./variables" as *;
.btn {
  border-radius: 35px;
  min-height: 60px;
  padding: 5px 25px;
  font-size: 1.1rem;
  font-family: $tt-medium;
  @media (max-width: 1199px) {
    padding: 5px 20px;
    min-height: 45px;
  }
  &.btn-small {
    min-height: 43px;
    padding: 5px 15px;
    line-height: 31px;
    @media (max-width: 576px) {
      min-height: 40px; 
      line-height: 28px;
      font-size: 1rem;
    }
  }
  &:focus {
    border: none;
    outline: none;
    box-shadow: none;
  }

  svg,
  img {
    max-width: 30px;
    height: auto;
    margin: 0 5px;
    @media (max-width: 576px) {
      max-width: 25px;
    }
  }
  .svg-warning {
    width: 35px;
    position: relative;
    top: 5px;
    left: -10px;
  }
  &.btn-default {
    background: #f5f5f5;
    box-shadow: inset 0px 4px 5px 0px lighten($black, 52);
    color: $black;
    &:hover {
      background: #f9f9f9;
    }
  }
  &.btn-primary {
    background: $primaryColor;
    box-shadow: inset 0px 4px 5px 0px darken($primaryColor, 12);
    border-color: $primaryColor;
    color: $white;
    &:hover {
      background: darken($primaryColor, 10);
    }
  }
  &.btn-secondary {
    background: $secondaryColor;
    box-shadow: inset 0px 4px 5px 0px darken($secondaryColor, 12);
    border-color: $secondaryColor;
    color: $white;
    &:hover {
      background: darken($secondaryColor, 10);
    }
  }
  &.btn-danger {
    background: $red;
    box-shadow: inset 0px 4px 5px 0px darken($red, 12);
    border-color: $red;
    color: $white;
    &:hover {
      background: darken($red, 10);
    }
  }
  &.btn-warning {
    background: $orange;
    box-shadow: inset 0px 4px 5px 0px darken($orange, 12);
    border-color: $orange;
    color: $white;
    &:hover {
      background: darken($orange, 10);
    }
  }

  &.btn-outline-primary {
    border-color: $primaryColor;
    color: $primaryColor;
    box-shadow: inset 0px 4px 5px 0px lighten($black, 85);
    &:hover {
      background-color: $primaryColor;
      color: $white;
      box-shadow: inset 0px 4px 5px 0px darken($primaryColor, 12);
    }
    &:focus {
      border: 1px solid $primaryColor;
    }
    &.btn-small {
      min-height: 43px;
      padding: 5px 15px;
      line-height: 31px;
      @media (max-width: 576px) {
        min-height: 40px; 
        line-height: 28px;
        font-size: 1rem;
      }
    }
  }
  &.btn-outline-secondary {
    border-color: $secondaryColor;
    color: $secondaryColor;
    box-shadow: inset 0px 3px 5px 0px lighten($black, 85);
    &:hover {
      background-color: $secondaryColor;
      color: $white;
      box-shadow: inset 0px 4px 8px 0px darken($secondaryColor, 12);
    }
    &:focus {
      border: 1px solid $secondaryColor;
    }
    &.btn-small {
      min-height: 43px;
      padding: 5px 15px;
      line-height: 31px;
      @media (max-width: 576px) {
        min-height: 40px; 
        line-height: 28px;
        font-size: 1rem;
      }
    }
  }
  &.btn-outline-danger {
    border-color: $red;
    color: $red;
    box-shadow: inset 0px 3px 5px 0px lighten($black, 85);
    &:hover {
      background-color: $red;
      color: $white;
      box-shadow: inset 0px 4px 8px 0px darken($red, 12);
    }
    &:focus {
      border: 1px solid $red;
    }
    &.btn-small {
      min-height: 43px;
      padding: 5px 15px;
      line-height: 31px;
      @media (max-width: 576px) {
        min-height: 40px; 
        line-height: 28px;
        font-size: 1rem;
      }
    }
  }
  &.btn-outline-warning {
    border-color: $orange;
    color: $orange;
    box-shadow: inset 0px 3px 5px 0px lighten($black, 85);
    &:hover {
      background-color: $orange;
      color: $white;
      box-shadow: inset 0px 4px 8px 0px darken($orange, 12);
    }
    &:focus {
      border: 1px solid $orange;
    }
    &.btn-small {
      min-height: 43px;
      padding: 5px 15px;
      line-height: 31px;
      @media (max-width: 576px) {
        min-height: 40px; 
        line-height: 28px;
        font-size: 1rem;
      }
    }
  }

  &.btn-icon {
    border-radius: 15px;
    font-size: 1.8rem;
    background: $white;
    border: none;
    position: relative;
    height: 52px;
    min-height: 52px;
    min-width: 52px;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      position: relative;
    }
    .badge {
      height: 10px;
      width: 10px;
      padding: 0;
      border-radius: 50%;
      position: absolute;
      top: 2px;
      right: 2px;
      background-color: $orange !important;
    }
    &:hover {
      color: $white;
      svg {
        path {
          stroke: $white;
        }
      }
    }
    &.btn-icon-primary {
      box-shadow: 0 3px 15px lighten($primaryColor, 20);
      color: $primaryColor;
      &:hover {
        background: $primaryColor;
        color: $white;
      }
    }
    &.btn-icon-secondary {
      box-shadow: 0 3px 15px lighten($secondaryColor, 20);
      color: $secondaryColor;
      &:hover {
        background: $secondaryColor;
        color: $white;
      }
    }
    &.btn-outline-primary {
      border: 1px solid $primaryColor;
      box-shadow: none;
      color: $primaryColor;
      &:hover {
        background: $primaryColor;
        color: $white;
      }
    }
    &.btn-outline-secondary {
      border: 1px solid $secondaryColor;
      box-shadow: none;
      color: $secondaryColor;
      &:hover {
        background: $secondaryColor;
        color: $white;
      }
    }
  }
  &.icon-start {
    svg {
      font-size: 2rem;
      color: $yellow;
      text-shadow: 0 2px 2px #000;
    }
  }
  &.btn-red {
    background: $white;
    font-size: 1.3rem;
    color: $red;
    height: 45px;
    width: 45px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 45px;
    box-shadow: -2px 4px 10px lighten($red, 35);
    &:hover {
      background: $red;
      color: $white;
    }
  }
  &.btn-blue {
    background: $white;
    font-size: 1.3rem;
    color: $secondaryColor;
    height: 45px;
    width: 45px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 45px;
    box-shadow: -2px 4px 10px lighten($secondaryColor, 15);
    &:hover {
      background: $secondaryColor;
      color: $white;
      svg {
        path {
          fill: #fff;
        }
      }
    }
    @media (max-width: 576px) {
      font-size: 1.2rem; 
      height: 40px;
      width: 40px; 
      min-height: 40px;
    }
  }
  &.btn-green {
    background: $white;
    font-size: 1.3rem;
    color: $primaryColor;
    height: 45px;
    width: 45px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 45px;
    box-shadow: -2px 4px 10px lighten($primaryColor, 15);
    &:hover {
      background: $primaryColor;
      color: $white;
      svg {
        path {
          fill: #fff;
        }
      }
    }
    @media (max-width: 576px) {
      font-size: 1.2rem; 
      height: 40px;
      width: 40px; 
      min-height: 40px;
    }
  }
  &.btn-orange {
    background: $white;
    font-size: 1.3rem;
    color: $orange;
    height: 45px;
    width: 45px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 45px;
    box-shadow: -2px 4px 10px lighten($orange, 15);
    &:hover {
      background: $orange;
      color: $white;
      svg {
        path {
          fill: $white;
        }
      }
    }
  }
}
