@use "../../../scss/abstracts/" as *;

.actionsFilter {
  display: flex;
  .btn {
    margin: 0 5px;
    flex: 1 0;
  }
}
.btn-addCat {
  .btn {
    width: 100%;
    padding: 5px 15px;
    svg {
      margin: 0 15px 0 0;
    }
    @media (max-width: 1550px) {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      span {
        display: inline-block;
        line-height: 20px;
        text-align: left;
      }
    }
  }
}
