@use "./variables" as *;

.rdt_Table {
  margin: 9px 0;
  height: auto !important;
  overflow-y: auto;
  .rdt_TableHeadRow {
    color: $primaryColor;
    background: lighten($primaryColor, 35);
    border-radius: 60px;
    border-bottom: none !important;
    margin-bottom: 5px;

    .rdt_TableCol {
      font-size: 1.15rem;
      font-family: $tt-demibold;
      padding: 15px 5px;
      display: flex;
      justify-content: center;
      align-items: center;

      &:last-child {
        min-width: 130px;
        justify-content: center;
      }
    }
  }

  .rdt_TableRow {
    border: 1px solid transparent !important;
    border-radius: 30px;
    overflow-y: visible;
    .rdt_TableCell {
      padding: 10px 5px;
      font-size: 1.05rem;
      display: grid;
      // display: flex;
      justify-content: center;
      font-family: $tt-medium;
      text-align: center;

      &:last-child {
        min-width: 130px;
        justify-content: center;
      }
      @media (max-width: 991px) {
        min-width: 150px;
      }
      @media (max-width: 576px) {
        padding: 5px 10px !important;
        font-size: 1rem !important;
      }
    }

    &:hover {
      border-color: #e4eeed !important;
    }
    @media (max-width: 991px) {
      border-color: #e4eeed !important;
      margin: 5px 0;
    }
  }
}

.table-secondary {
  .rdt_Table {
    .rdt_TableHeadRow {
      color: $secondaryColor;
      background: lighten($secondaryColor, 35);
    }
  }
}

.table-action {
  min-width: 150px;
  display: flex;
  justify-content: center;

  .btn {
    margin: 2px 4px;
  }

  .edit-odtva-btn {
    min-width: 45px !important;
    width: 17.5px !important;
    margin: 2px 10px;
  }
}

.tva-box {
  display: flex;
  flex-direction: column;
  .tva-box-input {
    display: flex;
    align-items: center;
    position: relative;
    border-radius: 30px;
    border: 1px solid $secondaryColor;
    min-width: 175px;
    height: 44px;
    padding: 0 45px 0 5px;
    .num-tva {
      display: inline-block;
      color: black;
      font-family: $tt-medium;
      font-size: 1.2rem;
      text-align: center;
      padding: 0 5px;
      width: 80px;
      border: none;
      background: transparent;

      &:focus {
        border: none;
        box-shadow: none;
        outline: none;
      }

      -moz-appearance: textfield;

      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      &::-o-inner-spin-button {
        -o-appearance: none;
        margin: 0;
      }
    }

    .device-tva {
      display: inline-block;
      padding: 0;
      line-height: 15px;
      min-width: 30px;
    }

    .icon-tva {
      color: $secondaryColor;
      background: $white;
      height: 40px;
      width: 40px;
      border-radius: 50%;
      position: absolute;
      border: none;
      font-size: 1.8rem;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 91;
      right: 1px;
      top: 1px;

      img {
        max-width: 30px;
        top: 2px;
        position: relative;
      }

      svg {
        max-width: 30px;
      }

      &.i-green {
        background-color: $primaryColor;
        box-shadow: -2px 4px 10px lighten($primaryColor, 35) !important;

        svg {
          path {
            fill: $white;
          }
        }
      }

      &.i-blue {
        background-color: $secondaryColor;
        box-shadow: -2px 4px 10px lighten($secondaryColor, 15) !important;

        svg {
          path {
            fill: $white;
          }
        }
      }

      &.i-orange {
        background-color: $orange;
        box-shadow: -2px 4px 10px lighten($orange, 15) !important;

        svg {
          path {
            fill: $white;
          }
        }
      }

      &.i-red {
        background-color: $red;
        box-shadow: -2px 4px 10px lighten($red, 35) !important;

        svg {
          path {
            fill: $white;
          }
        }
      }
    }

    &.i-primary {
      border-color: $primaryColor;
      box-shadow: inset 0px 4px 6px 0px lighten($primaryColor, 25);

      .icon-tva {
        box-shadow: inset 0px 4px 6px 0px lighten($primaryColor, 25);
        color: $primaryColor;
      }
    }

    &.i-secondary {
      border-color: $secondaryColor;
      box-shadow: inset 0px 4px 6px 0px lighten($secondaryColor, 25);

      .icon-tva {
        box-shadow: inset 0px 4px 6px 0px lighten($secondaryColor, 25);
        color: $secondaryColor;
      }
    }

    &.i-warning {
      border-color: $orange;
      box-shadow: inset 0px 4px 6px 0px lighten($orange, 25);

      .icon-tva {
        box-shadow: inset 0px 4px 6px 0px lighten($orange, 25);
        color: $orange;

        svg {
          path {
            fill: $orange;
          }
        }
      }
    }
  }
}

.categ-box {
  border-radius: 30px;
  min-width: 175px;
  min-height: 44px;
  padding: 0 45px 0 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  .categ-name {
    line-height: 16px;
  }
  .icon-tva {
    color: $secondaryColor;
    background: $white;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    position: absolute;
    border: none;
    font-size: 1.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 91;
    right: 1px;
    top: 1px;

    img {
      max-width: 30px;
      top: 2px;
      position: relative;
    }

    svg {
      max-width: 30px;
    }

    &.i-transparent {
      background-color: transparent;
      box-shadow: -2px 4px 10px lighten($secondaryColor, 15) !important;

      svg {
        path {
          fill: $white;
        }
      }
    }

    &.i-green {
      background-color: $primaryColor;
      box-shadow: -2px 4px 10px lighten($primaryColor, 35) !important;

      svg {
        path {
          fill: $white;
        }
      }
    }

    &.i-blue {
      background-color: $secondaryColor;
      box-shadow: -2px 4px 10px lighten($secondaryColor, 15) !important;

      svg {
        path {
          fill: $white;
        }
      }
    }

    &.i-orange {
      background-color: $orange;
      box-shadow: -2px 4px 10px lighten($orange, 15) !important;

      svg {
        path {
          fill: $white;
        }
      }
    }

    &.i-red {
      background-color: $red;
      box-shadow: -2px 4px 10px lighten($red, 35) !important;

      svg {
        path {
          fill: $white;
        }
      }
    }
  }

  &.i-primary {
    border-color: $primaryColor;
    // box-shadow: inset 0px 4px 6px 0px lighten($primaryColor, 25);

    .icon-tva {
      box-shadow: inset 0px 4px 6px 0px lighten($primaryColor, 25);
      color: $primaryColor;

      svg {
        fill: $primaryColor;
      }
    }
  }

  &.i-secondary {
    border-color: $secondaryColor;

    // box-shadow: inset 0px 4px 6px 0px lighten($secondaryColor, 25);
    .icon-tva {
      box-shadow: inset 0px 4px 6px 0px lighten($secondaryColor, 25);
      color: $secondaryColor;

      svg {
        color: $secondaryColor;
      }
    }
  }

  &.i-warning {
    border-color: $orange;
    box-shadow: inset 0px 4px 6px 0px lighten($orange, 25);

    .icon-tva {
      box-shadow: inset 0px 4px 6px 0px lighten($orange, 25);
      color: $orange;

      svg {
        path {
          fill: $orange;
        }
      }
    }
  }
}

.bMgaAx div:first-child {
  white-space: normal !important;
}

.invoice-table {
  .rdt_Table {
    // padding-bottom: 160px;
    .rdt_TableCell {
      max-height: 70px;
    }
    .rdt_TableRow .rdt_TableCell:last-child {
      min-width: 150px;
      justify-content: center;
    }
  }
}
