@use "../../../../scss/abstracts/" as *;

.register-stepTow {
  position: relative;
  padding-top: 90px;
}
.row-wrap {
  flex-wrap: nowrap;
  @media (max-width: 1199px) {
    flex-wrap: wrap;
    justify-content: center;
  }
}
.box-col {
  margin-bottom: 10px;
  @media (max-width: 1199px) {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  @media (max-width: 576px) {
    flex: 0 0 auto;
    width:50%;
  }
  @media (max-width: 420px) {
    flex: 0 0 auto;
    width:100%;
  }
}
