@import "./abstracts/index";

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  font-family: $tt-regular;
  color: $txtColor;
  font-size: $fontSizeNormal;
  line-height: 1.5;
  background-color: $bodyBg;
  overflow-x: hidden !important;
  overflow-y: auto !important;
}

a {
  text-decoration: none;
  color: unset;
}

a,
.btn,
button,
a:hover,
.btn:hover,
button:hover {
  transition: all 0.3s ease-in-out;
  outline: 0;
}

a,
button:focus,
.btn:focus {
  outline: 0;
  box-shadow: none;
}

.btn-check:active + .btn-primary:focus,
.btn-check:checked + .btn-primary:focus,
.btn-primary.active:focus,
.btn-primary:active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none;
}

img {
  width: 100%;
}

ul,
li {
  list-style-type: none;
}

.mb {
  margin-bottom: $spacing;

  @include tablet {
    margin-bottom: $mobileSpacing;
  }
}

.title {
  font-size: $fontSizeMedium;
  font-weight: 700;
}

.txt-success {
  color: $green;
}

.txt-danger {
  color: $red;
}

.txt-orange {
  color: $orange;
}

.card {
  border-radius: 40px;
  margin-bottom: 30px;
  padding: 15px;
  border: none;
  box-shadow: -20px 30px 99px rgba(114, 114, 114, 0.12);
  @media (max-width: 1199px) {
    border-radius: 30px;
  }
  @media (max-width: 991px) {
    margin-bottom: 15px;
  }
}

.tags-item {
  border: 1px solid $primaryColor;
  box-shadow: inset 0px 4px 5px 0px lighten($black, 85);
  padding: 6px 10px 6px 20px;
  border-radius: 25px;
  font-size: 1.5rem;
  font-family: $tt-medium;
  color: #b0b0b0;
  display: inline-flex;
  align-items: center;
  margin: 5px;

  span {
    font-size: 1.1rem;
    color: $txtColor;
    margin: 0 10px 0 0;
  }

  svg {
    cursor: pointer;
  }

  &.tags-primary {
    border: 1px solid lighten($primaryColor, 30);
    box-shadow: inset 0px 4px 5px 0px lighten($primaryColor, 30);
  }

  &.tags-secondary {
    border: 1px solid lighten($secondaryColor, 30);
    box-shadow: inset 0px 4px 5px 0px lighten($secondaryColor, 30);
  }

  &.tags-warning {
    border: 1px solid lighten($orange, 25);
    box-shadow: inset 0px 4px 5px 0px lighten($orange, 25);
  }

  &.tags-danger {
    border: 1px solid lighten($red, 45);
    box-shadow: inset 0px 4px 5px 0px lighten($red, 45);
  }
}

.react-datepicker__triangle {
  left: -10px !important;
}

.react-datepicker-popper {
  z-index: 99 !important;
}

.Toastify__progress-bar--warning {
  background: var(--toastify-text-color-warning) !important;
}

// ::-webkit-scrollbar {
//   width: 8px;
//   height: 8px;
// }

// ::-webkit-scrollbar-button {
//   width: 0px;
//   height: 0px;
// }

// ::-webkit-scrollbar-thumb {
//   background: $primaryColor;
//   border: 0px none $white;
//   border-radius: 4px;
// }

// ::-webkit-scrollbar-track {
//   background: $mainBg;
//   border: 1px none $white;
//   border-radius: 5px;
// }

.dBbhDz div:first-child {
  white-space: normal !important;
}

#proxy-renderer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.alert.alert-danger.fade.show {
  border-radius: 30px;
}

.spinner-grow.text-secondary {
  margin-top: 15px;
  &.text-secondary {
    color: lighten($secondaryColor, 35) !important;
  }
}

.my-clients-table {
  .rdt_TableCell {
    cursor: initial !important;
  }
}
