@use "../../scss/abstracts/" as *;

.actionsFilter {
  display: flex;
  .btn {
    margin: 0 5px;
    flex: 1 0;
  }
}
.btn-addCat {
  .btn {
    width: 100%;
    padding: 5px 15px;
    svg {
      margin: 0 12px 0 0;
    }
  }
  @media (max-width: 767px) {
    padding-top: 15px;
  }
}

.form-line.custom-form-line {
  .check-box {
    padding: 0 5px 0 0;
  }
}
