@use "../../../scss/abstracts/" as *;

.section-gestion-doc {
  padding: 25px 0 15px;
}

.document-item {
  min-height: 80px;
  padding: 5px 15px;
  border-radius: 15px;
  color: $secondaryColor;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 5px 0 20px;
  transition: all 0.3s;
  cursor: pointer;
  max-width: 300px;
  text-overflow: ellipsis;
  white-space: nowrap;

  &::before {
    content: "";
    position: absolute;
    border-radius: 15px;
    border: 2px dashed $secondaryColor;
    top: -5px;
    left: -5px;
    right: -5px;
    bottom: -5px;
  }

  &:hover {
    background: lighten($secondaryColor, 30);
    transition: all 0.3s;
  }

  .doc-icon {
    width: 30px;
    margin-right: 20px;

    img {
      max-width: 30px;
      height: auto;
    }
  }

  .doc-name {
    max-width: calc(100% - 30px);
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
  }

  &.doc-primary {
    border: 2px dashed $primaryColor;
    color: $primaryColor;

    &:hover {
      background: lighten($primaryColor, 30);
      transition: all 0.3s;
    }
  }

  &.doc-warning {
    border: 2px dashed $orange;
    color: $orange;

    &:hover {
      background: lighten($orange, 30);
      transition: all 0.3s;
    }
  }
}

.mission-statement {
  .modal-content {
    padding: 15px 0;
    .modal-body {
      max-height: 500px;
      overflow-y: auto;

      &::-webkit-scrollbar-track {
        border-radius: 10px;
        background-color: $mainBg;
      }
      &::-webkit-scrollbar {
        width: 5px;
        background-color: $mainBg;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: darken($mainBg, 30);
      }
    }
  }
}
