@use "../../../../scss/abstracts/" as *;

.register-stepThree {
  position: relative;
  padding-top: 90px;
}

.switch {
  display: flex;
  align-items: center;
  justify-content: center;

  .label {
    font-size: 1.1rem;
    color: $colorLink;
    padding: 0 15px;
    display: inline-block;

    &.greenTxt {
      color: $primaryColor;
    }
  }
}

.custom-switch {
  display: flex;
  align-items: center;
  justify-content: left;
  margin: 15px 0;

  .label {
    font-size: 1.1rem;
    color: $colorLink;
    padding: 0 15px;
    display: inline-block;

    &.orangeTxt {
      color: $orange;
    }
  }
}
.question-wrapper {
  display: flex !important;
  justify-content: space-between;
  text-align: start;
  svg {
    margin-left: 10px;
    fill: $primaryColor;
  }
  ul{
    padding: 10px 0;
  }
}

.answers-wrapper {
  display: flex !important;
  justify-content: center;
  align-items: center;
  p{
    margin-bottom: 5px;
  }
  svg {
    fill: $primaryColor;
  }
}