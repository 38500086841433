@use "../../scss/abstracts/" as *;

.actionsFilter {
  display: flex;
  .btn {
    margin: 0 5px;
    flex: 1 0;
  }
}

.rdt_TableCell {
  cursor: pointer;
}

.filterInner {
  @media (max-width: 750px) {
    .row {
      .col {
        flex: initial;
      }
    }
  }
}
.my-clients-table {
  border-radius: 2px;
  height: 100vh;
  .my-clients-table_ColHeader {
    text-align: center;
  }
  .InovuaReactDataGrid__cell {
    user-select: auto;
    -webkit-user-select: auto;
    .tva-table-checkbox {
      margin: 0;
      label {
        color: #555e68;
        fill: #99a1aa;
        font-size: 13.5px;
        flex: 1 1;
        width: 0;
        align-self: center;
      }
    }
    .tva-table-datePicker{
      color: #555e68;
      fill: #99a1aa;
      font-size: 13.5px;
      padding: 0;
      background: transparent;
      border: none;
      box-shadow: none;
      cursor: pointer;
    }
  }
  .my-clients-table_ActionButton {
    display: flex;
    justify-content: center;
    button {
      border: none;
      color: $red;
      padding: 5px 15px;
      border-radius: 5px;
    }
  }
}
