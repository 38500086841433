@use "../../scss/abstracts/" as *;

.top-odt-tva {
  padding: 40px 0 0;

  @media (max-width: 1550px) {
    padding: 40px 15px 0;
  }
  @media (max-width: 576px) {
    padding: 30px 0 0;
  }
}

.odt-pages {
  @media (max-width: 1550px) {
    padding: 0 15px;
  }
  @media (max-width: 576px) {
    padding: 0;
  }
}

.action-top-odt {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 15px;

  .month-label {
    font-size: 1.5rem;
    font-family: $tt-demibold;
    color: #000;
    text-transform: uppercase;
  }

  .btn {
    margin-right: 15px;
    min-width: 185px;
    min-height: 50px;

    :first-child {
      margin-right: 0;
    }
  }

  @media (max-width: 576px) {
    .month-label {
      margin-bottom: 15px;
    }

    flex-direction: column;

    .od-tva-wrapper {
      display: flex;
      flex-wrap: wrap;
      .btn {
        min-width: 120px;
        padding: 0 15px;
        margin: 0 5px 12px;
      }
    }
  }
}

.small-btns {
  display: flex;
  align-items: center;
  .btn {
    min-width: 100px;
  }
}

.odt-tva-Table {
  .rdt_Table .rdt_TableHeadRow .rdt_TableCol:last-child {
    justify-content: center;
    min-width: 300px;
  }

  .rdt_Table .rdt_TableRow .rdt_TableCell:last-child {
    min-width: 300px;
  }
}

.tva-recif-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    margin: 0 5px;
    fill: $orange;
  }
}

.delcared-odtva {
  color: $primaryColor;
  font-weight: 700;
  font-size: 1.1rem;
  svg {
    fill: $primaryColor;
  }
}

.reported-odtva {
  color: $orange;
  font-weight: 700;
  font-size: 1.1rem;

  svg {
    fill: $orange;
  }
}

.rectifi-popver-body {
  .tooltip-inner {
    text-align: start;
    padding: 10px;
    background-color: $white;
    opacity: 1 !important;
    span {
      color: $orange;
    }

    ul {
      margin: 0;
      padding: 0;
      color: $black;
    }
  }
}

.tva-follow-table {
  .InovuaReactDataGrid__header-group__title,
  .InovuaReactDataGrid__column-header__content {
    text-align: center;
  }
  .ca_inner_tva_cell {
    padding: 0 !important;
    table {
      margin: 0;
      td {
        width: 50%;
        text-align: center;
      }
      td:first-of-type {
        border-right: 1px solid #e4e3e2;
        font-weight: bold;
      }
      .last_ca_cell {
        border-bottom: none;
      }
    }
  }
  .ca_inner_documents_cell {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 !important;
    .InovuaReactDataGrid__cell__content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0;
      .documents-uploader {
        border-bottom: 1px solid #e4e3e2;
        display: block;
      }
      .documents-uploader,
      .documents-viewer {
        width: 100%;
        height: 100%;
        padding: 10px;
        cursor: pointer;
      }
    }
    .form-file {
      margin: 0 !important;
      padding: 5px !important;
      box-shadow: none;
      width: 100%;
      background: transparent;
      .file-box {
        padding: 0 !important;
        box-shadow: none;
        background: transparent;
        height: inherit;
        svg {
          margin: 0 !important;
          padding-top: 2px;
        }
      }
    }
  }
  .ca_inner_actions_cell {
    .table-action {
      gap: 10px;
      button {
        min-width: 10px;
      }
    }
  }
}
.trackedTVA-warnings {
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  p {
    font-weight: bold;
    color: $orange;
  }
}
