@use "../../scss/abstracts/" as *;

.card-packs {
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 20px;
  min-height: 250px;
  height: 100%;
  box-shadow: -5px 5px 30px lighten($secondaryColor, 25);
  justify-content: flex-end;
  align-items: center;
  overflow: hidden;
  position: relative;
  & > * {
    position: relative;
    z-index: 9;
  }
  &:after {
    content: "";
    position: absolute;
    border-radius: 20px;
    background: transparent;
    top: -2px;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 0;
  }
  .card-pack-head {
    text-align: center;
  }

  .check-pack {
    position: absolute;
    top: -40px;
    left: -45px;
    width: 105px;
    height: 88px;
    background: #fff;
    justify-content: center;
    border-radius: 0 50px 50px 0;
    label {
      position: absolute !important;
      bottom: 13px;
      right: 11px;
      width: 25px;
      height: 25px;
    }
    [type="radio"]:checked + label:after,
    [type="radio"]:not(:checked) + label:after {
      width: 13px;
      height: 13px;
      top: 6px;
      left: 6px;
      border-radius: 50%;
    }
  }

  h3 {
    font-size: 1.3rem;
    color: $black;
    font-family: $tt-demibold;
    margin: 0;
    text-transform: uppercase;
  }
  .price-pack {
    font-size: 35px;
    color: #000;
    line-height: 40px;
    font-family: $tt-extrabold;
    text-transform: uppercase;
    display: block;
  }
  .par-span {
    display: block;
    font-family: $tt-demibold;
    font-size: 1.3rem;
    margin-bottom: 15px;
  }
  .text-pack {
    padding: 15px;
    border-radius: 20px;
    color: #fff;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    ul{
      padding: 0;
    }
  }
  &.primary {
    box-shadow: -5px 5px 30px lighten($primaryColor, 25);
    &:after {
      background-image: linear-gradient(
        to bottom,
        #f6fcfa,
        #f8fdfd,
        #fbfdff,
        #fdfeff,
        #ffffff
      );
    }
    .check-pack {
      background: $primaryColor;
    }
    h3,
    .par-span {
      color: $primaryColor;
    }
    .text-pack {
      background-image: linear-gradient(
        to bottom,
        #56d0b5,
        #47bca2,
        #39a88f,
        #29957d,
        #18826b
      );
    }
  }

  &.secondary {
    box-shadow: -5px 5px 30px lighten($secondaryColor, 25);
    &:after {
      background-image: linear-gradient(
        to bottom,
        #f6fbfe,
        #f9fcff,
        #fbfdff,
        #fefeff,
        #ffffff
      );
    }
    .check-pack {
      background: $secondaryColor;
    }
    h3,
    .par-span {
      color: $secondaryColor;
    }
    .text-pack {
      background-image: linear-gradient(
        to bottom,
        #59c0ea,
        #4cb4df,
        #3fa8d3,
        #309cc8,
        #1f90bd
      );
    }
  }
  &.warning {
    box-shadow: -5px 5px 30px lighten($orange, 25);
    &:after {
      background-image: linear-gradient(
        to bottom,
        #fef3e5,
        #fff4ef,
        #fff7f9,
        #fffbfe,
        #ffffff
      );
    }
    .check-pack {
      background: $orange;
    }
    h3,
    .par-span {
      color: $orange;
    }
    .text-pack {
      background-image: linear-gradient(
        to top,
        #fe9717,
        #fe9e2a,
        #fea539,
        #feac47,
        #feb254
      );
    }
  }
}

.tva-span{
  color: #fe9717;
  font-weight: 700;
}
